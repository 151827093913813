<template>
	<page-container :page="page">
		<heading-image
			v-if="page.headingImage"
			:video="page.youtubeVideoID"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		>
			<template #heroContent>
				<div v-if="defaults[locale].homepage.headingTitle" class="hero-content">
					<div class="hero-logo" />
					<span class="hero-title">
						{{ defaults[locale].homepage.headingTitle }}
					</span>
					<span class="hero-subtitle">
						{{ defaults[locale].homepage.headingSubTitle }}
					</span>
				</div>
			</template>
		</heading-image>

		<main-content :title="page.title || page.header" :subtitle="page.subtitle" :content="page.content">
			<template #bgImage>
				<picture>
					<source srcset="~/assets/images/index-intro-pattern-b.webp" type="image/webp" />
					<source srcset="~/assets/images/index-intro-pattern-b.png" />
					<img
						class="fade-image"
						src="~/assets/images/index-intro-pattern.webp"
						alt="Morena Resort"
						loading="lazy"
					/>
				</picture>
				<div class="intro-bg" />
			</template>
		</main-content>

		<booking-benefits
			:title="defaults[locale].homepage.sectionBenefitsTitle"
			:subtitle="defaults[locale].homepage.sectionBenefitsSubtitle"
			:benefits="defaults[locale].homepage.bookingBenefits"
			:image="{
				image: defaults[locale].homepage.sectionBenefitsImage,
				imageAlt: defaults[locale].homepage.sectionBenefitsImageAlt,
				imageWebp: defaults[locale].homepage.sectionBenefitsImageWebp,
			}"
		/>

		<rooms-promotions
			:items="roomsData"
			:header="defaults[locale].rooms.sectionRoomsTitle"
			:subtitle="defaults[locale].rooms.subtitle"
			:content="defaults[locale].rooms.sectionRoomsContent"
		/>

		<contentblocks
			v-if="page && page.contentblocks && page.contentblocks.length"
			:contentblocks="page.contentblocks"
			:content-blocks-content="page.sectionContentblocksContent"
			:content-blocks-subtitle="page.sectionContentblocksTitle"
		/>

		<promoblocks
			:header="defaults[locale].homepage.sectionPromotionTitle"
			:subtitle="defaults[locale].homepage.sectionPromotionSubtitle"
			:promoblocks="promoblocksData"
			:amount="4"
		/>

		<newsletter
			v-if="page && page.showNewsletter"
			:header="defaults[locale].newsletter.title"
			:content="defaults[locale].newsletter.content"
			:button="defaults[locale].newsletter.button"
		/>

		<news-overview :news="news" />
		<frequently-asked-questions
			v-if="page && page.frequentlyAskedQuestion"
			:header="page.frequentlyAskedQuestion.header"
			:items="page.frequentlyAskedQuestion.faqitems"
		/>
		<pre-footer
			v-if="page && page.footerSection && page.footerSection.length"
			:footer-section="page.footerSection[0]"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});

const { data: news } = await useWebsiteFetch('news', {
	query: { language: locale.value },
	key: `${locale.value}/news`,
});

const { data: roomsData } = await useWebsiteFetch('rooms', {
	query: { language: locale.value },
	key: `${locale.value}/rooms`,
});
</script>

<style lang="scss" scoped>
.fade-image {
	width: 50%;
	opacity: 0.5;
}

.intro-bg {
	background: url('~/assets/images/index-intro-pattern.png') repeat top left;
	position: absolute;
	inset: 0;
	z-index: 1;
	display: none;
}

.hero-content {
	position: absolute;
	top: calc(50% - 184px);
	left: 0;
	right: 0;
	z-index: 3;
	text-align: center;
	padding: 0 20px;
	color: #fff;
	animation: fade-from-bottom 1.4s ease;

	.hero-logo {
		width: 80%;
		max-width: 500px;
		height: 131px;
		background: url('~/assets/images/logo-morena-resort-white-heading.png') no-repeat center center;
		background-size: 100%;
		margin: 0 auto 60px;
	}

	span {
		color: #fff;
		font-weight: 500;
		font-size: 52px;
		letter-spacing: 1px;
		width: 100%;
		text-transform: uppercase;
		display: inline-block;
		margin: 0 0 10px;
	}

	.hero-subtitle {
		font-size: 26px;
		font-weight: 300;
		font-family: var(--body-font-family);
		text-transform: none;
		margin: 10px auto 0;
		max-width: 700px;
	}
}

@media (max-width: 1500px) {
	.hero-content {
		span {
			font-size: 46px;
		}
	}
}

@media (max-width: 920px) {
	.fade-image {
		width: 100%;
		display: none;
	}

	.intro-bg {
		display: block;
	}
}

@media (max-width: 880px) {
	.hero-content {
		span {
			font-size: 42px;
		}

		.hero-subtitle {
			font-size: 22px;
		}
	}
}

@media (max-width: 680px) {
	.hero-content {
		span {
			font-size: 36px;
		}

		.hero-subtitle {
			font-size: 20px;
		}
	}
}

@media (max-width: 520px) {
	.hero-content {
		span {
			font-size: 28px;
		}

		.hero-subtitle {
			font-size: 18px;
		}
	}
}
</style>
